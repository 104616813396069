@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

body {
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
    height: 100%;
    background-color: #f5f5f5;
}

.ant-layout,
.ant-layout * {
    font-family: 'Raleway', sans-serif;
}

::-webkit-scrollbar {
    width: 0;
}

.star-icon {
    color: red;
    position: relative;
    bottom: 6px;
    font-size: 10px;
    margin-left: 4px;
}

hr {
    border-width: 0.5px;
}
